import * as React from 'react';
// components
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// utils
import { getImage } from 'gatsby-plugin-image';
import { linkWithSlash } from '../lib/functions';

// types & interfaces
import type { RelatedArticle } from '../lib/types';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
export interface RelatedArticleCardProps {
    article: RelatedArticle;
    className?: string;
}

const RelatedArticleCard: React.FC<RelatedArticleCardProps> = ({
    article,
    className = ''
}) => {
    // get article cover image
    let cover: IGatsbyImageData | string | undefined;
    if (!article.cover?.localFile.childImageSharp) {
        cover = article.cover?.localFile.publicURL;
    } else {
        cover = getImage(article.cover?.localFile);
    }

    return (
        <div className={`${className} space-y-4 flex flex-col`}>
            <h3 className="text-base">
                <Link
                    className="font-bold text-dark-100 hover:underline hover:text-secondary-100"
                    to={linkWithSlash(
                        `${article.basePath}/${article.SEO.slug}`
                    )}>
                    {article.title}
                </Link>
            </h3>

            <Link to={linkWithSlash(`${article.basePath}/${article.SEO.slug}`)}>
                {cover &&
                    (typeof cover === 'string' ? (
                        <img
                            src={cover}
                            alt={`Image de Couverture article ${article.title}`}
                            className={
                                'rounded-md w-full object-cover object-center h-52 md:h-40 shadow-center-dark'
                            }
                        />
                    ) : (
                        <GatsbyImage
                            image={cover}
                            alt={`Image de Couverture article ${article.title}`}
                            style={{
                                objectFit: 'cover',
                                width: '100%'
                            }}
                            className={
                                'rounded-md w-full object-cover object-center h-52 md:h-40 shadow-center-dark'
                            }
                        />
                    ))}
            </Link>
            <p className={'text-sm'}>{article.summary}</p>
        </div>
    );
};

export default RelatedArticleCard;
